<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">CLIENTE</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="8" md="2">
                                <v-text-field class='text-caption' v-model="rut" label="Rut" outlined dense required :disabled="disabledRut" :filled="filledRut"></v-text-field>
                            </v-col>
                            <v-col cols="4" md="1"><v-btn @click="enviarRut(rut)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn></v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Existe !
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nuevo !
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    <span class="text-caption grey--text">>>> Rut: 99999999-X</span>
                                </v-alert>
                            </v-col >
                            <v-col cols="12" md="6">
                                <v-text-field class='text-caption' v-model="nombre" label="Nombre" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="direccion" label="Direccion" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="comuna" label="Comuna" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' v-model="ciudad" label="Ciudad" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="observacion" label="Giro" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n4">
                            <v-col cols="12" md="10">
                                <v-row class="mt-n4">
                                    <v-col cols="12" md="4">
                                        <v-text-field class='text-caption' v-model="contacto" label="Contacto"  outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field class='text-caption' v-model="telefono" label="Telefono" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field class='text-caption' v-model="email" label="E-mail"  outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-n4">    
                                    <v-col cols="6" md="2">
                                        <v-select class='text-caption' v-model="estado" :items="estados" label="Estado" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col> 
                                    <v-col cols="6" md="2">
                                        <v-select @change="myCredito()" class='text-caption' v-model="credito" :items="creditos" label="Credito" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col> 
                                    <v-col cols="6" md="2">
                                        <v-select v-if="credito=='SI'" class='text-caption' v-model="dias" :items="credito_dias" label="Dias" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                        <v-select v-else class='text-caption' v-model="dias" :items="credito_dias" label="Dias" dense outlined disabled filled></v-select>
                                    </v-col> 
                                    <v-col cols="6" md="2">
                                        <v-select class='text-caption' v-model="precio" :items="precios" label="Precio" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-card height="110">
                                    <v-card-text>
                                        <v-chip @click="openDirections()">
                                            <h5 class="indigo--text">
                                                <v-icon color="indigo" small>add_circle</v-icon>
                                                Más Direcciones
                                            </h5>
                                        </v-chip>
                                        <v-chip class="mt-3" @click="openContacto()">
                                            <h5 class="indigo--text">
                                                <v-icon color="indigo" small>add_circle</v-icon>
                                                Más Contactos
                                            </h5>
                                        </v-chip>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="mt-n10">
                <v-spacer></v-spacer>
                <v-btn  @click="newCliente()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                <v-btn  @click="saveCliente()" class="mr-5" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
            </v-card-actions>
        </v-card>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />

        <!-- dialogo mas Direcciones -->
        <v-dialog v-model="d_directions" transition="dialog-bottom-transition" max-width="50%" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Más Direcciones</v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <v-alert class='text-caption' v-if="alertacontacto=='si'" dense text type="success">
                            Dirección Creada !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertacontacto=='no'" dense text type="error">
                            Sin Dirección !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Ingrese Dirección !!!
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="newdirection" label="Nueva Direccion" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' v-model="newcomuna" label="Comuna" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="newciudad" label="Ciudad" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1">
                        <div v-if="alertacontacto!='si'">
                           <v-btn @click="newDirections()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn @click="newCategoria()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                        <div v-else>
                            <v-btn small fab dark color="grey"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="12" md="12">
                        <v-data-table dense dark :headers="directionHead" :items="directionList" 
                            :page.sync="page_direction" :items-per-page="itemsPerPage_direction" 
                            @page-count="pageCount_direction = $event" hide-default-footer class="elevation-1 text-size">
                            <template v-slot:item.accion="{ item }">
                                <v-icon small class="white--text" @click="deleteDirection(item)">delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-pagination v-model="page_direction" :length="pageCount_direction" :total-visible="5"></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDirection()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- dialogo mas Contactos -->
        <v-dialog v-model="d_contactos" transition="dialog-bottom-transition" max-width="50%" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Más Contactos</v-toolbar>
            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <v-alert class='text-caption' v-if="alertacontacto=='si'" dense text type="success">
                            Contacto Creado !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertacontacto=='no'" dense text type="error">
                            Sin Contacto !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Ingrese Contacto !!!
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row class="mt-n5">
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="newcontacto" label="Nuevo Contacto" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field class='text-caption' v-model="newfono" label="Telefono" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='text-caption' v-model="newemail" label="Email" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1">
                        <div v-if="alertacontacto!='si'">
                           <v-btn @click="newContacto()" small fab dark color="indigo"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn @click="newCategoria()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                        <div v-else>
                            <v-btn small fab dark color="grey"><v-icon>playlist_add</v-icon></v-btn>
                            <!-- <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn> -->
                        </div>
                    </v-col>
                </v-row>
                <v-row class="mt-n8">
                    <v-col cols="12" md="12">
                        <v-data-table dense dark :headers="contactoHead" :items="contactoList" 
                            :page.sync="page_contacto" :items-per-page="itemsPerPage_contacto" 
                            @page-count="pageCount_contacto = $event" hide-default-footer class="elevation-1 text-size">
                            <template v-slot:item.accion="{ item }">
                                <v-icon small class="white--text" @click="deleteContacto(item)">delete</v-icon>
                            </template>
                        </v-data-table>
                        <v-pagination v-model="page_contacto" :length="pageCount_contacto" :total-visible="5"></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeContacto()" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </div>    
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Cliente',
    components:{dialogo, snack},
    props:['usu','contrato'],
    data(){
        return{
            //contrato:'123',
            disabledRut:false,
            filledRut:false,
            disabledDatos:true,
            filledDatos:true,
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',
            rut: '',
            nombre: '',
            direccion:'',
            comuna:'',
            ciudad:'',
            contacto:'',
            email: '',
            telefono:'',
            observacion:'',
            estados:['V','N'],
            estado:'V',
            creditos:['SI','NO'],
            credito:'NO',
            precio:'Precio Detalle',
            precios:['Precio Detalle','Precio x Mayor','Precio Oferta'],
            credito_dias:['0','15','30','45','60','90'],
            dias:'0',
            //mas direcciones
            d_directions:false,
            newdirection:'',
            newcomuna:'',
            newciudad:'',
            alertadirection:'',
            directionHead:[
                { text: 'ID', align: 'center', sortable: false, value: 'id' },
                { text: 'Dirección', align: 'start', sortable: false, value: 'name' },
                { text: 'Comuna', align: 'start', sortable: false, value: 'comuna' },
                { text: 'Ciudad', align: 'start', sortable: false, value: 'ciudad' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            directionList:[],
            page_direction: 1,
            pageCount_direction: 0,
            itemsPerPage_direction: 10,
            //mas contactos
            d_contactos:false,
            newcontacto:'',
            newfono:'',
            newemail:'',
            alertacontacto:'',
            contactoHead:[
                { text: 'ID', align: 'center', sortable: false, value: 'id' },
                { text: 'Contacto', align: 'start', sortable: false, value: 'contacto' },
                { text: 'Telefono', align: 'start', sortable: false, value: 'fono' },
                { text: 'Email', align: 'start', sortable: false, value: 'email' },
                { text: '', align: 'center', sortable: false, value: 'accion' }
            ],
            contactoList:[],
            page_contacto: 1,
            pageCount_contacto: 0,
            itemsPerPage_contacto: 10,
        }
    },
    methods:{
        //NEW DIRECCION
        async openDirections(){
            this.dialogo=true
            if(this.rut!=''){
                //leer todas para listar
                this.directionList=[];
                try {
                    const resDirection = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/direcciones/' + this.rut + "/" + this.contrato);
                    const dataDirection=resDirection.data;
                    if(dataDirection[0].existe=='si'){
                        for (let index = 0; index < dataDirection.length; index++) {
                            const element = dataDirection[index];
                            this.directionList.push({
                                id: element.ID,
                                name: element.Direccion,
                                comuna: element.Comuna,
                                ciudad: element.Ciudad
                            });                            
                        }
                    }
                    this.d_directions=true                    
                } catch (error) {
                    console.log(error)
                }
            } else {
                this.Msg='Ingrese Cliente'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.dialogo=false
        },
        async newDirections(){
            console.log('new Direction')
            this.dialogo=true;
            try {
                //grabar direccion
                var parametros={
                    'contrato': this.contrato,
                    'rut': this.rut,
                    'direccion': this.newdirection,
                    'comuna': this.newcomuna,
                    'ciudad': this.newciudad
                };
                const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/clientes/direcciones/',parametros);
                const datos=response.data;
                if (datos=='si'){
                    //leer la ultima ingresada
                    const resDirection = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/direcciones/' + this.rut + "/" + this.contrato);
                    const dataDirection=resDirection.data;
                    if(dataDirection[0].existe=='si'){
                        this.directionList.push({
                            id: dataDirection[0].ID,
                            name: dataDirection[0].Direccion,
                            comuna: dataDirection[0].Comuna,
                            ciudad: dataDirection[0].Ciudad
                        });
                    }
                }                
            } catch (error) {
                console.log(error)
            }
            this.newdirection=''
            this.dialogo=false;
        },
        async deleteDirection(line){
            console.log('delete Direction')
            this.dialogo=true;
            //eliminar de la BBDD com el id
            try {
                const myID = line.id
                const response = await axios.delete(process.env.VUE_APP_URL + '/api/inv/clientes/direcciones/'+ myID + "/" + this.rut + "/" + this.contrato);
                const datos=response.data;
                if (datos=='si'){
                    //eliminar de la lista
                    this.directionList = this.directionList.filter(res => res.id != line.id);
                }                
            } catch (error) {
                console.log(error)
            }
            this.newdirection=''
            this.dialogo=false;
        },
        closeDirection(){
            console.log('close Direction')
            this.newdirection='';
            this.d_directions=false
        },
        //NEW CONTACTO
        async openContacto(){
            this.dialogo=true
            if(this.rut!=''){
                //leer todas para listar
                this.contactoList=[];
                try {
                    const resContacto = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/contactos/' + this.rut + "/" + this.contrato);
                    const dataContacto=resContacto.data;
                    if(dataContacto[0].existe=='si'){
                        for (let index = 0; index < dataContacto.length; index++) {
                            const element = dataContacto[index];
                            this.contactoList.push({
                                id: element.ID,
                                contacto: element.Contacto,
                                fono: element.Telefono,
                                email: element.Email,
                            });                            
                        }
                    }
                    this.d_contactos=true
                } catch (error) {
                    console.log(error)
                }
            } else {
                this.Msg='Ingrese Cliente'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
            this.dialogo=false
        },
        async newContacto(){
            console.log('new Contacto')
            this.dialogo=true;
            try {
                //grabar contacto
                var parametros={
                    'contrato': this.contrato,
                    'rut': this.rut,
                    'contacto': this.newcontacto,
                    'fono': this.newfono,
                    'email': this.newemail
                };
                const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/clientes/contactos/',parametros);
                const datos=response.data;
                if (datos=='si'){
                    //leer la ultima ingresada
                    const resContacto = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/contactos/' + this.rut + "/" + this.contrato);
                    const dataContacto=resContacto.data;
                    if(dataContacto[0].existe=='si'){
                        this.contactoList.push({
                            id: dataContacto[0].ID,
                            contacto: dataContacto[0].Contacto,
                            fono: dataContacto[0].Telefono,
                            email: dataContacto[0].Email,
                        });                            
                    }
                }                
            } catch (error) {
                console.log(error)
            }
            this.newcontacto='';
            this.newfono='';
            this.newemail='';
            this.dialogo=false;
        },
        async deleteContacto(line){
            console.log('delete Contacto')
            this.dialogo=true;
            //eliminar de la BBDD com el id
            try {
                const myID = line.id
                const response = await axios.delete(process.env.VUE_APP_URL + '/api/inv/clientes/contactos/'+ myID + "/" + this.rut + "/" + this.contrato);
                const datos=response.data;
                if (datos=='si'){
                    //eliminar de la lista
                    this.contactoList = this.contactoList.filter(res => res.id != line.id);
                }                
            } catch (error) {
                console.log(error)
            }
            this.newcontacto='';
            this.newfono='';
            this.newemail='';
            this.dialogo=false;
        },
        closeContacto(){
            console.log('close Contacto')
            this.newcontacto='';
            this.newfono='';
            this.newemail='';
            this.d_contactos=false
        },

        myCredito(){
            if(this.credito=='SI'){
                this.dias='30'
            } else {
                this.dias='0'
            }
        },
        enviarRut(rutCompleto){
            let ruts=this.validaRut(rutCompleto);
            if (ruts){
                this.verCliente(rutCompleto)
            } else {
                this.Msg='Rut no Valido'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        validaRut(rutCompleto){
            if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			    return false;
		    var tmp 	= rutCompleto.split('-');
		    var digv	= tmp[1]; 
		    var ruti 	= tmp[0];
		    if ( digv == 'K' ) digv = 'k' ;
		    return (this.dv(ruti) == digv );
        },
        dv(T){
            var M=0,S=1;
    		for(;T;T=Math.floor(T/10))
	    		S=(S+T%10*(9-M++%6))%11;
		    return S?S-1:'k';
        },
        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        clsInput(){
            this.nombre='';
            this.direccion='';
            this.ciudad='';
            this.comuna='';
            this.telefono='';
            this.email='';
            this.observacion='';
            this.estado='V';
            this.credito='NO';
            this.dias='0';
            this.precio='Precio Detalle';
            this.contacto='';
        },
        async verCliente(xRut){
             this.loading=true;
             this.msgbox=false;
             this.clsInput();
            if(xRut.trim()!=''){
                this.disabledRut=true;
                this.filledRut=true;
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/'+this.contrato+'/'+ xRut);
                const datos=response.data;
                console.log('Cliante:',datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                    this.restoDatos(false);
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newCliente()

                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.rut=datos[0].Rut;
                    this.nombre=datos[0].Nombre;
                    this.direccion=datos[0].Direccion;
                    this.ciudad=datos[0].Ciudad;
                    this.comuna=datos[0].Comuna;
                    this.telefono=datos[0].Telefono;
                    this.email=datos[0].Email;
                    this.observacion=datos[0].Observacion;
                    this.estado=datos[0].Estado;
                    this.credito=datos[0].Credito;
                    this.contacto=datos[0].Contacto;
                    if(datos[0].Dias!=null){
                        this.dias=datos[0].Dias.toString();
                    } else { this.dias='0' };
                    this.precio=datos[0].Precio;
                    this.restoDatos(false);
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        async saveCliente(){
             this.loading=true;
             this.msgbox=false;
            if (this.contrato!='' &&
                this.rut!='' && this.nombre!=''){
                var parametros={
                    'contrato': this.contrato,
                    'rut': this.rut,
                    'nombre': this.nombre,
                    'direccion': this.direccion,
                    'ciudad': this.ciudad,
                    'comuna': this.comuna,
                    'telefono': this.telefono,
                    'email': this.email,
                    'observacion': this.observacion,
                    'estado': this.estado,
                    'credito':this.credito,
                    'dias':this.dias,
                    'precio': this.precio,
                    'contacto': this.contacto
                };
                if (this.existe=='si'){
                    // actualizar
                    const response = await axios.put(process.env.VUE_APP_URL + '/api/inv/v3/clientes/actualiza/'+this.rut,parametros);
                    const datos=response.data;
                    console.log(datos);
                    if (datos=='si'){

                        //verificar si existe la direccion en la lista
                        const found_direction = this.directionList.filter(res => res.name == this.direccion && res.comuna == this.comuna && res.ciudad == this.ciudad);
                        if(found_direction.length==0){
                            //grabar en direcciones
                            var parametros={
                                'contrato': this.contrato,
                                'rut': this.rut,
                                'direccion': this.direccion,
                                'comuna': this.comuna,
                                'ciudad': this.ciudad,
                            };
                            const resDirection = await axios.post(process.env.VUE_APP_URL + '/api/inv/clientes/direcciones/',parametros);
                            const dataDirection=resDirection.data;
                            console.log(dataDirection);
                        }
                        //verificar si existe el contacto en la lista
                        const found_contact = this.contactoList.filter(res => res.contacto == this.contacto && res.fono == this.telefono && res.email == this.email);
                        if(found_contact.length==0){
                            //grabar contacto
                            var parametros={
                                'contrato': this.contrato,
                                'rut': this.rut,
                                'contacto': this.contacto,
                                'fono': this.telefono,
                                'email': this.email
                            };
                            const resContact = await axios.post(process.env.VUE_APP_URL + '/api/inv/clientes/contactos/',parametros);
                            const dataContact=resContact.data;
                            console.log(dataContact);
                        }

                        this.Msg='Modificacion de Cliente Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newCliente()
                    }
                }
                if (this.existe=='no'){
                    // ingresar                    
                    const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/v3/clientes/nuevo',parametros);
                    const datos=response.data;
                    console.log(datos);
                    if (datos=='si'){

                        //verificar si existe la direccion en la lista
                        const found_direction = this.directionList.filter(res => res.name == this.direccion && res.comuna == this.comuna && res.ciudad == this.ciudad);
                        if(found_direction.length==0){
                            //grabar en direcciones
                            var parametros={
                                'contrato': this.contrato,
                                'rut': this.rut,
                                'direccion': this.direccion,
                                'comuna': this.comuna,
                                'ciudad': this.ciudad,
                            };
                            const resDirection = await axios.post(process.env.VUE_APP_URL + '/api/inv/clientes/direcciones/',parametros);
                            const dataDirection=resDirection.data;
                            console.log(dataDirection);
                        }
                        //verificar si existe el contacto en la lista
                        const found_contact = this.contactoList.filter(res => res.contacto == this.contacto && res.fono == this.telefono && res.email == this.email);
                        if(found_contact.length==0){
                            //grabar contacto
                            var parametros={
                                'contrato': this.contrato,
                                'rut': this.rut,
                                'contacto': this.contacto,
                                'fono': this.telefono,
                                'email': this.email
                            };
                            const resContact = await axios.post(process.env.VUE_APP_URL + '/api/inv/clientes/contactos/',parametros);
                            const dataContact=resContact.data;
                            console.log(dataContact);
                        }

                        this.existe='si';               
                        this.Msg='Nuevo Cliente Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newCliente()
                    }
                }
                // if (this.existe==''){
                //     this.Msg='Tiene que validar Rut'
                //     this.msgbox=true;
                //     this.color='red'
                // }            
            } else {
                    this.Msg='Rut y Nombre obligatorios'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
            }
            this.loading=false;
        },
        newCliente(){
            this.loading=true;
            this.msgbox=false;
            this.rut='';
            this.clsInput();
            this.disabledRut=false;
            this.filledRut=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
    }
}
</script>